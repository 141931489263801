import react, { memo, useEffect, useRef } from 'react';
import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setFinished } from '../../redux/uiSlice';
import { ChatStateEnum, UIStateEnum } from '../../redux/types';

const EndSignalHandler: React.FC = () => {
    const dispatch = useDispatch();
    const uiState = useSelector((state: RootState) => state.ui.state);
    const endSignal = useSelector((state: RootState) => state.chat.end_signal);
    const chatState = useSelector((state: RootState) => state.chat.state);

    // set a timeout to set the chat state to finished when the end signal is received
    const endSignalTimeout = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (endSignal === true && chatState === ChatStateEnum.LISTENING && uiState === UIStateEnum.active) {
            endSignalTimeout.current = setTimeout(() => {
                dispatch(setFinished());
            }, 2000);
        }
    }, [endSignal, chatState]);


    return null;
};

export default memo(EndSignalHandler);