import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setCurrentUserMessage, setCurrentUserMessageInProgress, clearCurrentUserMessageInProgress } from "../../redux/chatSlice";
import { ChatStateEnum } from "../../redux/types";

const UserTextBox: React.FC = () => {
    const dispatch = useDispatch();
    const chatState = useSelector((state: RootState) => state.chat.state);
    const currentUserMessageInProgress = useSelector((state: RootState) => state.chat.currentUserMessageInProgress);
    //const userTextBox = document.querySelector('textarea[name="postContent"]') as HTMLTextAreaElement;
    const userTextBoxRef = useRef<HTMLTextAreaElement>(null);

    const handleMessage = () => {
        const message = userTextBoxRef.current?.value;
        dispatch(setCurrentUserMessage());
    }

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        dispatch(clearCurrentUserMessageInProgress());
        dispatch(setCurrentUserMessageInProgress(event.target.value));
    }


    return (
        <>
            <div className="flex flex-col pt-4 pb-4 h-10vh w-[60vw]">
                <textarea className={`${[ChatStateEnum.LISTENING].includes(chatState) ? "bg-slate-50" : "enabled:false bg-slate-400"} relative z-50 translate-x-[4vw] w-[65vw] h-[7vh] text-[3vw] font-semibold p-7 mb-3 rounded-tl-xl rounded-bl-xl rounded-tr-xl drop-shadow-3xl`} 
                name="postContent" 
                ref={userTextBoxRef}
                rows={4} 
                cols={40}
                disabled={[ChatStateEnum.THINKING, ChatStateEnum.POLLING, ChatStateEnum.SPEAKING].includes(chatState)}
                value={currentUserMessageInProgress}
                placeholder={[ChatStateEnum.LISTENING].includes(chatState) ? "Start Speaking Now..." : ""}
                onChange={handleChange}
                />
                <button className="flex justify-end translate-x-[4vw] bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-fit" onClick={handleMessage}>Submit</button>
            </div>
        </>
    );
};

export default UserTextBox;