import React from 'react';
import { useState, useEffect } from 'react';
import { useGetQrCodeUrlQuery } from '../redux/backendSlice';
import { LogApiArg, EventLogRequest, useLogMutation } from '../redux/georgebotApi';

interface FinishedWindowProps {
    cid: string;
    onComplete: () => void;
}

const FinishedWindow: React.FC<FinishedWindowProps> = ({ cid, onComplete }) => {
    const [event, { data: eventData, error: eventError, isLoading: eventLoading }] = useLogMutation();
    const [seconds, setSeconds] = useState<number>(30);
    const [qrCodeUrl, setQrCodeUrl] = useState<string>('');
    const { data, error, isLoading } = useGetQrCodeUrlQuery(cid);
    const pauseSpeechEvent = new CustomEvent("speechPause");
    dispatchEvent(pauseSpeechEvent);
    useEffect(() => {
        if (seconds > 0) {
            const timerId = setTimeout(() => setSeconds(seconds - 1), 1000);
            return () => clearTimeout(timerId);
        }
        else {
            onComplete();
        }
    }, [seconds]);

    useEffect(() => {
        if (data) {
            setQrCodeUrl(data);
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            console.error(error);
            event({
                "x-token": localStorage.getItem("token") as string,
                eventLogRequest: {
                    cid: cid,
                    event: "kiosk_error",
                    data: { "error": `Error in FinishedWindow getting QR Code: ${error}` },
                } as EventLogRequest
            } as LogApiArg);
        }
    }, [error]);

    useEffect(() => {
        event({
            "x-token": localStorage.getItem("token") as string,
            eventLogRequest: {
                cid: cid,
                event: "thankyou_page_loaded",
                data: {},
            } as EventLogRequest
        } as LogApiArg);
    }, []);



    return (
        <div className={`absolute drop-shadow-2xl mt-[2vh] p-20 z-40 bg-slate-300 w-[94vw] h-[95vh] rounded-3xl`}>
            <img className="rounded-[15%] w-full p-12 h-auto drop-shadow-2xl" src={qrCodeUrl} alt="QR Code" />
            <h1 className="text-[6vw] font-semibold text-center">Thank you for chatting!</h1>
            <h1 className="text-[4vw] text-center">SCAN QR CODE FOR CONVERSATION SUMMARY, GREAT PRODUCTS YOU'LL LOVE & MORE!</h1>
            <br />
            <h2 className="text-[4vw] font-semibold text-center text-red-600">Resetting in {seconds} <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-fit" onClick={onComplete}>Skip</button></h2>
        </div>
    );
};

export default FinishedWindow;