import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../redux/store";
import { UIStateEnum, ChatStateEnum } from "../../redux/types";
import { setFinished, setActive, showPopup, hidePopup } from '../../redux/uiSlice';
import { EventLogRequest, LogApiArg, TerminateApiArg, TerminateRequest, useLogMutation, useTerminateMutation } from "../../redux/georgebotApi";

const InactivityTimer: React.FC = () => {
    const [event, { data: eventData, error: eventError, isLoading: eventLoading }] = useLogMutation();
    const dispatch = useDispatch();
    const chatState = useSelector((state: RootState) => state.chat.state);
    const [terminate, { data, error: terminateError, isLoading }] = useTerminateMutation();
    const inactivityTimer = useRef<NodeJS.Timeout | null>(null);
    const popupTimer = useRef<NodeJS.Timeout | null>(null);

    const resetInactivityTimer = () => {
        if (inactivityTimer.current) {
            clearTimeout(inactivityTimer.current);
        }
        if (popupTimer.current) {
            clearTimeout(popupTimer.current);
        }
        popupTimer.current = setTimeout(() => {
            // After the time elapses, show the popup
            const currentUiState = store.getState().ui.state;
            console.log("uiState ", currentUiState);
            if (currentUiState === UIStateEnum.active) {
                console.log("showing popup");
                dispatch(showPopup());
                const pauseSpeechEvent = new CustomEvent("speechPause");
                window.dispatchEvent(pauseSpeechEvent);
                const currentCid = store.getState().chat.cid;
                event({
                    "x-token": localStorage.getItem("token") as string,
                    eventLogRequest: {
                        cid: currentCid?.toString(),
                        event: "inactivity_timer_finished",
                        data: {},
                    } as EventLogRequest
                } as LogApiArg);
            }
            if (currentUiState === UIStateEnum.finished) {
                dispatch(hidePopup());
                dispatch(setFinished());
            }
        }, 15000);

        inactivityTimer.current = setTimeout(() => {
            // After the time elapses, terminate the chat
            const currentUiState = store.getState().ui.state;
            const currentCid = store.getState().chat.cid;
            if (currentUiState === UIStateEnum.active) {
                console.log("terminating chat");
                if (currentCid) {
                    terminate({
                        "x-token": localStorage.getItem("token"),
                        terminateRequest: {
                            cid: currentCid,
                            method: "timeout"
                        } as TerminateRequest
                    } as TerminateApiArg);
                    event({
                        "x-token": localStorage.getItem("token") as string,
                        eventLogRequest: {
                            cid: currentCid.toString(),
                            event: "inactivity_timer_finished",
                            data: {},
                        } as EventLogRequest
                    } as LogApiArg);
                }
                dispatch(hidePopup());
                dispatch(setFinished());
            }
        }, 30000);
    }

    const handleUserInteraction = () => {
        console.log("resetting inactivity timer");
        const popupShown = store.getState().ui.showPopup;
        if (popupShown) {
            dispatch(hidePopup());
            const isMuted = store.getState().chat.muted;
            if (isMuted === false) {
                const resumeSpeechEvent = new CustomEvent("speechResume");
                window.dispatchEvent(resumeSpeechEvent);
            }
            const currentCid = store.getState().chat.cid;
            event({
                "x-token": localStorage.getItem("token") as string,
                eventLogRequest: {
                    cid: currentCid?.toString(),
                    event: "inactivity_timer_dismissed",
                    data: {},
                } as EventLogRequest
            } as LogApiArg);
        }
        resetInactivityTimer();
    }

    useEffect(() => {
        handleUserInteraction();
    }, [chatState]);

    useEffect(() => {
        window.addEventListener("click", handleUserInteraction);
        window.addEventListener("mousedown", handleUserInteraction);
        window.addEventListener("speechStart", handleUserInteraction);
        window.addEventListener("speechEnd", handleUserInteraction);
        window.addEventListener("keydown", handleUserInteraction);
        window.addEventListener("scroll", handleUserInteraction);
        window.addEventListener("touchstart", handleUserInteraction);
        window.addEventListener("touchmove", handleUserInteraction);
        window.addEventListener("wakeword", handleUserInteraction);


        resetInactivityTimer();

        const inactivityResetInterval = setInterval(() => {
            const currentChatState = store.getState().chat.state;
            if ([ChatStateEnum.SPEAKING, ChatStateEnum.POLLING, ChatStateEnum.THINKING].includes(currentChatState)) {
                handleUserInteraction();
            }
        }, 1000);

        return () => {
            window.removeEventListener("click", handleUserInteraction);
            window.removeEventListener("mousedown", handleUserInteraction);
            window.removeEventListener("speechStart", handleUserInteraction);
            window.removeEventListener("speechEnd", handleUserInteraction);
            window.removeEventListener("keydown", handleUserInteraction);
            window.removeEventListener("scroll", handleUserInteraction);
            window.removeEventListener("touchstart", handleUserInteraction);
            window.removeEventListener("touchmove", handleUserInteraction);
            window.removeEventListener("wakeword", handleUserInteraction);

            if (inactivityTimer.current) {
                clearTimeout(inactivityTimer.current);
            }

            if (popupTimer.current) {
                clearTimeout(popupTimer.current);
            }
            clearInterval(inactivityResetInterval);
        }
    }, []);
    return null;
};

export default InactivityTimer;