import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SkuGroup } from '../../redux/georgebotApi';
import { CarouselStateEnum } from '../../redux/types';
import { useGetProductImageMutation } from "../../redux/backendSlice";


export interface Slide {
    product: SkuGroup;
    imageUrl: string;
}

interface CarouselState {
    slides: Slide[];
    topPicks: Slide[];
    state: CarouselStateEnum;
}


const initialState: CarouselState = {
    slides: [],
    topPicks: [],
    state: CarouselStateEnum.TOPPICKS,
};

const carouselSlice = createSlice({
    name: 'productCarousel',
    initialState,
    reducers: {
        addSlide: (state, action: PayloadAction<Slide>) => {
            // Remove the slide if it already exists
            state.slides = state.slides.filter((slide) => slide.product.sgid !== action.payload.product.sgid);
            // Add to the beginning of the array
            state.state = CarouselStateEnum.RECOMMENDED;
            if (action.payload.product.is_substitute === true
                && state.slides[0].product.is_substitute === false) {
                    // Add the substitute as the second slide
                    state.slides.splice(1, 0, action.payload);
                }
            else {
                state.slides.unshift(action.payload);
            }
        },
        removeSlide: (state, action: PayloadAction<number>) => {
            state.slides.splice(action.payload, 1);
        },
        updateSlide: (state, action: PayloadAction<{ index: number, slide: Slide }>) => {
            const index = state.slides.findIndex((slide) => slide.product.sgid === action.payload.index);
            if (index !== -1) {
                state.slides[index] = action.payload.slide;
            }
        },
        setSlides: (state, action: PayloadAction<Slide[]>) => {
            state.slides = action.payload;
            state.state = CarouselStateEnum.RECOMMENDED;
        },
        setTopPicks: (state, action: PayloadAction<Slide[]>) => {
            state.topPicks = action.payload;
        },
        setSlidesToTopPicks: (state) => {
            state.slides = state.topPicks;
            state.state = CarouselStateEnum.TOPPICKS;
        }
    }
});

export const { addSlide, removeSlide, updateSlide, setSlides, setTopPicks, setSlidesToTopPicks } = carouselSlice.actions;
export default carouselSlice.reducer;