import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import uiReducer from './uiSlice';
import { georgebotApi } from './georgebotApi';
import carouselReducer from '../components/product/carouselSlice';
import chatReducer from './chatSlice';
import { backendSlice } from './backendSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        ui: uiReducer,
        [georgebotApi.reducerPath]: georgebotApi.reducer,
        [backendSlice.reducerPath]: backendSlice.reducer,
        carousel: carouselReducer,
        chat: chatReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(georgebotApi.middleware)
            .concat(backendSlice.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
