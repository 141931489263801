import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { store, RootState } from "../../redux/store";
import { useNavigate } from 'react-router-dom';
import { SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { setCurrentBotMessage, setListening, setCurrentUserMessageInProgress, setCurrentUserMessage, setCid, clearCurrentUserMessageInProgress } from "../../redux/chatSlice";
import { InitiateRequest, 
    InitiateApiArg, 
    PollMessage,
    SkuGroup, 
    useInitiateMutation } from "../../redux/georgebotApi";
import { setStarting, setActive } from "../../redux/uiSlice";
import { useGetProductImageMutation } from "../../redux/backendSlice";
import ProductSkuDetail from "./ProductSkuDetail";
import { UIStateEnum } from "../../redux/types";

type ProductCarouselSlideProps = {
    product: SkuGroup;
    imageUrl: string;
};

// type InitiateRequest = components["schemas"]["InitiateRequest"];
// type PollMessage = components["schemas"]["PollMessage"];

const ProductCarouselSlide: React.FC<ProductCarouselSlideProps> = ({ product, imageUrl }) => {
    const [initiate, { data }] = useInitiateMutation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const firstSku = Object.values(product.skus)[0].sku;
    const productType = product.type;
    const productAbv = product.alcohol_by_volume;
    const uiState = useSelector((state: RootState) => state.ui.state);

    const handleInitiate = async (request: InitiateRequest, userMessage: string = '') => {
        const initResponse = await initiate({
            "x-token": localStorage.getItem("token") as string,
            initiateRequest: request
        } as InitiateApiArg).unwrap();
        let cid = initResponse.cid;
        const greetingMessage: PollMessage = {
            response_id: "1",
            index: 0,
            text: "Hello, how can I help you today?",
            sku_groups: [],
            end_signal: false,
        };
        dispatch(setCid(cid));

        if (userMessage !== '') {
            dispatch(setActive());
            dispatch(setListening());
            dispatch(setCurrentUserMessageInProgress(userMessage));
            dispatch(setCurrentUserMessage());

        }
        else {
            dispatch(setActive());
            dispatch(setCurrentBotMessage(greetingMessage as PollMessage));
        }
    }

    const askAbout = () => {
        const currentUiState = store.getState().ui.state;
        const userMessage = `Tell me more about ${product.display_name}`;
        console.log("Ask about button clicked");
        switch (currentUiState) {
            case "idle":
                dispatch(clearCurrentUserMessageInProgress());
                dispatch(setStarting());
                try {
                    let store_num, kiosk_num;
                    let client_id = "";
                    if (localStorage.getItem("store_num")) {
                        store_num = parseInt(localStorage.getItem("store_num") as string);
                    }
                    else {
                        navigate("/auth");
                    }
                    if (localStorage.getItem("kiosk_num")) {
                        kiosk_num = parseInt(localStorage.getItem("kiosk_num") as string);
                    }
                    else {
                        navigate("/auth");
                    }
                    if (localStorage.getItem("client_id")) {
                        client_id = localStorage.getItem("client_id") as string;
                    }
                    else {
                        navigate("/auth");
                    }
                    const request: InitiateRequest = {
                        guest: true,
                        store_num: store_num,
                        kiosk_num: kiosk_num,
                        bottleclub_id: "",
                        client_id: client_id,
                        purpose: "chat",
                        greeting: "Hello, how can I help you today?",
                    }
                    handleInitiate(request, userMessage);
                }
                catch (error) {
                    console.error("Failed to initiate:", error);
                }
                break;
        }
    }

    return (
        <>
            <div className="w-full h-full py-4 px-4 rounded-b-3xl">
                {/* {isLoading ? <p>Loading...</p> : null}
                {error ? <p>Error loading image</p> : null} */}
                <h1 className="text-[5vw] line-clamp-2 font-semibold antialiased leading-tight py-4 px-8">{product.display_name}</h1>
                <div className="flex flex-row h-5/6 py-10 px-8">
                    <div className="flex flex-col align-middle justify-center w-[33%] h-full max-h-full">
                        <img className="flex align-middle justify-center drop-shadow-3xl max-h-[40vh] max-w-full pr-5 pb-2 object-contain rounded-xl" src={`${imageUrl}`} alt={product.display_name} />
                        <span className="flex align-middle justify-center text-[2vw]">{productType}</span>
                        {productAbv > 0 ? <span className="flex align-middle justify-center text-[2vw]">{productAbv}% ABV</span> : null}
                    </div>
                    <div className="flex flex-col w-full">
                        <button onClick={askAbout} className={`${ uiState !== UIStateEnum.idle && "hidden" } bg-blue-500 text-5xl hover:bg-blue-700 text-white font-bold h-[5vh] max-h-[5vh] w-fit py-2 px-4 mx-5 mb-5 rounded-lg border-indigo-800 drop-shadow-xl`}>
                            <span>Ask about</span>
                        </button>
                        <div className="grid grid-flow-row w-full h-2/3 max-h-[66%] overflow-scroll">
                            {Object.values(product.skus).map((sku) => (
                                <ProductSkuDetail key={sku.sku} sku={sku} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductCarouselSlide;