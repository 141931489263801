import React, { memo, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { store, RootState } from "../../redux/store";
import {
    addBotMessageToQueue,
    updateCurrentBotMessageFromQueue,
    setPolling,
    clearBotMessageQueue
} from "../../redux/chatSlice";
import { PollStateEnum } from "../../redux/types";
import { LogApiArg, EventLogRequest, PollMessage, PollApiArg, usePollQuery, useLogMutation } from "../../redux/georgebotApi";
import { clear } from "console";


interface PollRequestProps {
    cid: string;
    runId: string;
    onComplete: (error: boolean) => void;
}

const MessagePoller: React.FC<PollRequestProps> = ({ cid, runId, onComplete }) => {
    const dispatch = useDispatch();
    const [event, { data: eventData, error: eventError, isLoading: eventLoading }] = useLogMutation();
    const [pollingInterval, setPollingInterval] = React.useState<number>(300);
    const [pollRequest, setPollRequest] = useState<PollApiArg>({
        cid: cid,
        runId: runId,
        interrupt: false,
        terminate: false,
        "x-token": localStorage.getItem("token") as string
    });
    //const currentBotMessageBuffer = useSelector((state: RootState) => state.chat.currentBotMessageBuffer);
    const botMessageQueue = useSelector((state: RootState) => state.chat.botMessageQueue).messages;
    const pollCounter = useRef<number>(0);
    const pollCounterMax = 150;
    
    dispatch(setPolling());
    // Poll for messages using RTK Query
    const { data, error: pollError } = usePollQuery(pollRequest, {
        pollingInterval: pollingInterval,
    });


    // Add new messages to the queue
    useEffect(() => {
        const currentBotMessageQueue = store.getState().chat.botMessageQueue.messages;
        pollCounter.current = 0;
        if (data?.messages) {
            try {
            console.log('Polling counter:', pollCounter.current);
            // Filter out duplicate messages by comparing IDs (or another unique property)
            const newMessages = data.messages.filter(
                (msg) => !currentBotMessageQueue.some((prevMsg) => prevMsg.message.response_id === msg.response_id)
            );
            // Order new messages by index increasing
            newMessages.sort((a, b) => a.index - b.index);
            // Add each new message to the queue
            newMessages.forEach((msg) => {
                pollCounter.current = 0;
                console.log('Adding message to queue:', msg.text);
                dispatch(addBotMessageToQueue(msg as PollMessage));
            });
        } catch (err) {
            console.error("Error adding messages to queue:", err);
            event({
                "x-token": localStorage.getItem("token") as string,
                eventLogRequest: {
                    cid: cid,
                    event: "kiosk_error",
                    data: {
                        "error": `Error in MessagePoller adding messages to queue: ${err}`,
                        "run_id": runId,
                    },
                } as EventLogRequest
            } as LogApiArg);
        }
        }
        if (data?.status === PollStateEnum.COMPLETED || data?.status === PollStateEnum.ERROR || pollRequest?.interrupt === true) {
            setPollingInterval(0);
            onComplete(data?.status === PollStateEnum.ERROR);
        }
    }, [data]);

    useEffect(() => {
        if (pollError) {
            console.error("Poll error:", pollError);
            event({
                "x-token": localStorage.getItem("token") as string,
                eventLogRequest: {
                    cid: cid,
                    event: "kiosk_error",
                    data: {
                        "error": `Error in MessagePoller API Query: ${pollError.toString()}`,
                        "run_id": runId,},
                } as EventLogRequest
            } as LogApiArg);
            setPollingInterval(0);
            dispatch(clearBotMessageQueue());
            dispatch(addBotMessageToQueue({
                response_id: "error",
                text: "I'm so sorry. I had a problem generating a response. You can scan the QR code on the next screen for a conversation summary, or start a new conversation and I'll do my best to help you.",
                index: 0,
                end_signal: true,
            } as PollMessage));
            onComplete(true);
        }
    }, [pollError]);

    useEffect(() => {
        const pollCounterInterval = setInterval(() => {
            pollCounter.current++;
            if (pollCounter.current >= pollCounterMax) {
                console.error("Poll timeout: runId:", runId);
                event({
                    "x-token": localStorage.getItem("token") as string,
                    eventLogRequest: {
                        cid: cid,
                        event: "kiosk_error",
                        data: {
                            "error": `Error in MessagePoller API Query: Timeout`,
                            "run_id": runId}
                    } as EventLogRequest
                } as LogApiArg);
                setPollingInterval(0);
                dispatch(clearBotMessageQueue());
                dispatch(addBotMessageToQueue({
                    response_id: "error",
                    text: "I'm so sorry. I had a problem generating a response. You can scan the QR code on the next screen for a conversation summary, or start a new conversation and I'll do my best to help you.",
                    index: 0,
                    end_signal: true,
                } as PollMessage));
                clearInterval(pollCounterInterval);
                onComplete(true);
            }
        }, 1000);
        return () => clearInterval(pollCounterInterval);
    }, []);
    


    return null;
}

export default memo(MessagePoller);