import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { useSelector, useDispatch } from "react-redux";
import { store, RootState, AppDispatch } from "../../redux/store";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import type { Swiper as SwiperType } from "swiper";
import { Pagination } from "swiper/modules";
import { useGetProductImageMutation } from "../../redux/backendSlice";
import { EventLogRequest, LogApiArg, useLogMutation } from "../../redux/georgebotApi";
import { SkuGroup, TopPicksRequest, TopPicksApiArg, useTopPicksMutation } from "../../redux/georgebotApi";
import ProductCarouselSlide from "./ProductCarouselSlide";
import { Slide, addSlide, removeSlide, updateSlide, setSlides, setSlidesToTopPicks, setTopPicks } from "./carouselSlice";


export const ProductCarousel: React.FC = () => {
    const [event, { data: eventData, error: eventError, isLoading: eventLoading }] = useLogMutation();
    const [getTopPicks, { data: topPicks, error }] = useTopPicksMutation();
    const dispatch = useDispatch<AppDispatch>();
    const slides = useSelector((state: RootState) => state.carousel.slides);
    const swiperRef = useRef<SwiperType | null>(null);
    const [getImageUrl] = useGetProductImageMutation();

    const logUserSwipe = () => {
        event({
            "x-token": localStorage.getItem("token") as string,
            eventLogRequest: {
                cid: store.getState().chat.cid?.toString(),
                event: "user_product_view_swipe",
                data: {},
            } as EventLogRequest
        } as LogApiArg);
    };


    useEffect(() => {
        // Actions to take when the slides are updated
        if (swiperRef.current !== null) {
            swiperRef.current.updateSlides();

            // Slide to the first slide, and reset autoplay timer
            swiperRef.current.slideTo(0);
            swiperRef.current.autoplay.start();
        }
    }, [slides]);

    useEffect(() => {
        if (error) {
            console.error("Error fetching top picks:", error);
            event({
                "x-token": localStorage.getItem("token") as string,
                eventLogRequest: {
                    cid: store.getState().chat.cid?.toString(),
                    event: "kiosk_error",
                    data: { error: error.toString() },
                } as EventLogRequest
            } as LogApiArg);
        }
    }, [error]);

    useEffect(() => {
        // Populate Top Picks carousel with products
        if (topPicks?.sku_groups !== undefined) {
            // get images and slides for each product
            const newSlides: Promise<Slide[]> = Promise.all(
                (topPicks.sku_groups ?? []).map(async (skuGroup: SkuGroup) => {
                    const firstSku = Object.values(skuGroup.skus)[0].sku;
                    // Await the result of the getImageUrl promise
                    try {
                        const imageUrl = await getImageUrl(firstSku).unwrap();
                        return { product: skuGroup, imageUrl: imageUrl };
                    } catch (err: any) {
                        console.error("Error fetching product image:", err);
                        event({
                            "x-token": localStorage.getItem("token") as string,
                            eventLogRequest: {
                                cid: store.getState().chat.cid?.toString(),
                                event: "kiosk_error",
                                data: { error: err.toString() },
                            } as EventLogRequest
                        } as LogApiArg);
                        return { product: skuGroup, imageUrl: "" };
                    }
                })
            );
            newSlides.then((slides) => {
                const currentUiState = store.getState().ui.state;
                dispatch(setTopPicks(slides));
                if (currentUiState === "idle") {
                    dispatch(setSlidesToTopPicks());
                    if (swiperRef.current !== null) {
                        swiperRef.current.autoplay.start();
                    }
                }
            }).catch((error) => {
                console.error("Error fetching product images:", error);
                event({
                    "x-token": localStorage.getItem("token") as string,
                    eventLogRequest: {
                        cid: store.getState().chat.cid?.toString(),
                        event: "kiosk_error",
                        data: { error: `Error setting top picks in carousel: ${error.toString()}` },
                    } as EventLogRequest
                } as LogApiArg);
            });
        }
    }, [topPicks]);

    useEffect(() => {
        getTopPicks({
            "x-token": localStorage.getItem("token") as string,
            topPicksRequest: {
                client_id: localStorage.getItem("client_id") as string,
                store_num: parseInt(localStorage.getItem("store_num") as string),
                limit: 5,
                offset: 0,
            } as TopPicksRequest,
        } as TopPicksApiArg);
    }, []);

    return (
        <div className="w-full h-full drop-shadow-3xl">
            <Swiper
                onSwiper={(swiper: SwiperType) => {
                    swiperRef.current = swiper;
                }}
                modules={[Pagination, Autoplay]}
                pagination={{ clickable: true }}
                className="w-full h-[55vh] max-h-[55vh] bg-gray-100 rounded-b-3xl"
                autoplay={{ delay: 10000, disableOnInteraction: false }}
                loop={true}
                slidesPerView={1}
                touchRatio={2}
                threshold={1}
            >
                {slides.map((slide, index) => (
                    <SwiperSlide key={index}>
                        <ProductCarouselSlide product={slide.product} imageUrl={slide.imageUrl} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default ProductCarousel;