import React, { useEffect, useState } from "react";
import { SkuDetail } from "../../redux/georgebotApi";
import './skuDetail.css';


type ProductSkuDetailProps = {
    sku: SkuDetail;
};


const ProductSkuDetail: React.FC<ProductSkuDetailProps> = ({ sku }) => {
    let onSale: boolean;
    const inStock : boolean = sku.qty_in_stock > 0;
    if (sku.list_price !== null && sku.sale_price !== null) {
        onSale = sku.sale_price < sku.list_price;
    } else {
        onSale = false;
    }
    return (
            <div className="w-full h-auto py-4 px-4">
                <span className={`item-size ${inStock ? 'in-stock' : 'out-of-stock'}`}>{sku.size}</span>{onSale ? <span className="list-price-strikethrough">${sku.list_price}</span> : ""}<span className={`sale-price ${onSale ?? 'on-sale'}`}>  ${sku.sale_price}</span>
                <br /><span className="qty-in-stock">{sku.qty_in_stock > 0 ? `${sku.qty_in_stock} in stock |` : `Out of Stock |`} </span><span className="location">{sku.location != null ? sku.location : ""}</span>
                <br /><span className="points">{sku.bottle_club_points > 0 ? `${sku.bottle_club_points} BC Points` : ""}</span>
                <br />
            </div>
    );
};

export default ProductSkuDetail;