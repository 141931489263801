import React, { useEffect } from 'react';

import logo from './logo.svg';
import './App.css';
import AuthPage from './pages/AuthPage';
import HomePage from './pages/HomePage';
import EventTestPage from './pages/EventTestPage';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';
import { useGetEnvMutation, envVars } from './redux/backendSlice';
import TTSTest from './pages/TTSTest';

function App() {
  const token = useSelector((state: RootState) => state.auth.token);
  const REACT_APP_ENV_MODE = process.env.REACT_APP_ENV_MODE;
  console.log('REACT_APP_ENV_MODE IS:', REACT_APP_ENV_MODE);
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.tsx</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    <Router>
      <Routes>
        {/* <Route path="/" element={<TTSTest />} /> */}
         <Route path="/auth" element={<AuthPage />} />
        <Route path="/" element={token ? <HomePage /> : <Navigate to="/auth" />} />
      </Routes>
    </Router>
  );
};

export default App;
