import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, } from "react-redux";
import { store } from "../../redux/store";
import { setStarting, setActive, setIdle } from "../../redux/uiSlice";
import { setCurrentBotMessageBuffer, setCid, clearCurrentUserMessageInProgress } from "../../redux/chatSlice";
import { InitiateApiArg, PollMessage, InitiateRequest, InitiateResponse, EventLogRequest, LogApiArg, useInitiateMutation, useLogMutation } from "../../redux/georgebotApi";

//type EventLogRequest = components["schemas"]["EventLogRequest"];

const ChatAvatar: React.FC = () => {
    const [initiate, { data, error: initiateError, isLoading }] = useInitiateMutation();
    const [event, { data: eventData, error: eventError, isLoading: eventLoading }] = useLogMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (initiateError) {
            console.error("Failed to initiate:", initiateError);
            dispatch(setIdle());
        }
    }, [initiateError]);

    const handleAvatarClick = async () => {
        const currentUiState = store.getState().ui.state;
        switch (currentUiState) {
            case "idle":
                dispatch(clearCurrentUserMessageInProgress());
                dispatch(setStarting());
                try {
                    let store_num, kiosk_num;
                    let client_id = "";
                    if (localStorage.getItem("store_num")) {
                        store_num = parseInt(localStorage.getItem("store_num") as string);
                    }
                    else {
                        navigate("/auth");
                    }
                    if (localStorage.getItem("kiosk_num")) {
                        kiosk_num = parseInt(localStorage.getItem("kiosk_num") as string);
                    }
                    else {
                        navigate("/auth");
                    }
                    if (localStorage.getItem("client_id")) {
                        client_id = localStorage.getItem("client_id") as string;
                    }
                    else {
                        navigate("/auth");
                    }
                    const token = localStorage.getItem("token");
                    if (!token) {
                        navigate("/auth");
                    }
                    else {
                        const request: InitiateRequest = {
                            guest: true,
                            store_num: store_num,
                            kiosk_num: kiosk_num,
                            bottleclub_id: "",
                            client_id: client_id,
                            purpose: "chat",
                            greeting: "Hello, how can I help you today?",
                        }
                        const initResponse = await initiate({
                            "x-token": token,
                            initiateRequest: request
                        } as InitiateApiArg);
                        let cid = initResponse.data?.cid;
                        event({
                            "x-token": token,
                            eventLogRequest: {
                                event: "user_initiate_touch",
                                cid: cid,
                                data: { method: "touch" },
                            } as EventLogRequest
                        } as LogApiArg);
                        const greetingMessage: PollMessage = {
                            response_id: "1",
                            index: 0,
                            text: "Hello, how can I help you today?",
                            sku_groups: [],
                            end_signal: false,
                        };
                        dispatch(setCid(cid));
                        dispatch(setActive());
                        event({
                            "x-token": token,
                            eventLogRequest: {
                                event: "chat_page_loaded",
                                cid: cid,
                                data: { method: "touch" },
                            } as EventLogRequest
                        } as LogApiArg);
                        dispatch(setCurrentBotMessageBuffer(greetingMessage as PollMessage));
                    }
                }
                catch (error) {
                    console.error("Failed to initiate after avatar click:", error);
                }
                break;
        }
    };

    return (
        <div className="flex items-center justify-center align-middle p-2 pb-4 w-auto">
            <img
                className={`rounded-full h-[15vh] cursor-pointer drop-shadow-4xl`}
                src="gg-preferred-icon.png"
                alt="George"
                onClick={handleAvatarClick}
            />
        </div>
    );
};

export default ChatAvatar;