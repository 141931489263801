import React, { useState } from 'react';
import { useAuthMutation, AuthApiArg, AuthRequest } from '../redux/georgebotApi';
import { AuthState } from '../redux/types';
import { useDispatch } from 'react-redux';
import { setToken } from '../redux/authSlice';
import { useNavigate } from 'react-router-dom';
import './AuthPage.css';


const AuthPage: React.FC = () => {
    const [storeNum, setStoreNum] = useState(0);
    const [kioskNum, setKioskNum] = useState(0);
    const [clientId, setClientId] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [auth, { data, error, isLoading }] = useAuthMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleAuth = async () => {
        const requestBody: AuthRequest = {
            store_num: storeNum,
            kiosk_num: kioskNum,
            client_id: clientId,
            client_secret: clientSecret,
        };

        try {
            const authResponse = await auth({
                authRequest: requestBody
            } as AuthApiArg).unwrap();
            const authState: AuthState = {
                token: authResponse.token,
                store_num: storeNum,
                kiosk_num: kioskNum,
                client_id: clientId,
            };
            dispatch(setToken(authState));
            navigate('/');
        } catch (error) {
            console.error('Failed to auth:', error);
        }
    };

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h2 className="mt-10 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900">Login to continue</h2>
                </div>
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form onSubmit={handleAuth} className="space-y-6">
                        <div>
                            <label htmlFor="store" className="block text-2xl font-medium leading-5 text-gray-700">
                                Store
                            </label>
                            <div className="mt-2">
                                <input
                                    type="number"
                                    placeholder="Store Number"
                                    value={storeNum.toString()}
                                    required
                                    onChange={(e) => setStoreNum(parseInt(e.target.value))}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-2xl sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="kiosk" className="block text-2xl font-medium leading-5 text-gray-700">
                                Kiosk
                            </label>
                            <div className="mt-2">
                                <input
                                    type="number"
                                    placeholder="Kiosk Number"
                                    value={kioskNum}
                                    required
                                    onChange={(e) => setKioskNum(parseInt(e.target.value))}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-2xl sm:leading-6"
                                />
                            </div>

                        </div>

                        <div>
                            <label htmlFor="client" className="block text-2xl font-medium leading-5 text-gray-700">
                                Client
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    placeholder="Client ID"
                                    value={clientId}
                                    required
                                    onChange={(e) => setClientId(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-2xl placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-2xl sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="secret" className="block text-2xl font-medium leading-5 text-gray-700">
                                Secret
                            </label>
                            <div className="mt-2">
                                <input
                                    type="password"
                                    placeholder="Client Secret"
                                    value={clientSecret}
                                    required
                                    onChange={(e) => setClientSecret(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-2xl sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                onClick={handleAuth}
                                disabled={isLoading}
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-2xl font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                {isLoading ? 'Authenticating... ' : 'Authenticate'}
                            </button>
                        </div>
                    </form>
                    {error && <p>Authentication Failed</p>}
                </div>
            </div>
        </>
    );
};

export default AuthPage;