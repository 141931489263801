import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';

export interface envVars {
    envMode: string;
    apiUrl: string;
}

const REACT_APP_ENV_MODE = process.env.REACT_APP_ENV_MODE;
console.log('REACT_APP_ENV_MODE IS:', REACT_APP_ENV_MODE);
let apiUrl = 'http://localhost:5000';
if (REACT_APP_ENV_MODE === 'staging') {
    apiUrl = process.env.REACT_APP_STAGING_BACKEND_URL as string;
}
if (REACT_APP_ENV_MODE === 'production') {
    apiUrl = process.env.REACT_APP_PRODUCTION_BACKEND_URL as string;
}

export const backendSlice = createApi({
    reducerPath: 'backend',
    baseQuery: fetchBaseQuery({
        baseUrl: apiUrl,
    }),
    endpoints: (builder) => ({
        getEnv: builder.mutation<envVars, void>({
            query: () => ({
                url: 'env',
                method: 'GET',
                retry: 3,
            }),
        }),
        getProductImage: builder.mutation<string, string>({
            query: (sku : string ) => ({
                url: `product-image?sku=${sku}`,
                method: 'GET',
                retry: 3,
                responseHandler: (response) => response.blob(),
            }),
            transformResponse: (response : Blob): string => {
                return URL.createObjectURL(response);
            }
        }),
        getQrCodeUrl: builder.query<string, string>({
            query: (cid: string) => ({
                url: `qr-code?cid=${cid}&client_id=${localStorage.getItem("client_id")}`,
                method: 'GET',
                retry: 3,
                // add localhost to the URL
                
            }),
            transformResponse: (response: any): string => {
                return `${response.url}`;
            }
        }),
    }),
});

export const { useGetEnvMutation, useGetProductImageMutation, useGetQrCodeUrlQuery } = backendSlice;
