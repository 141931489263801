import React, { useEffect } from "react";
import { RootState, store } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setSpeaking, setEndSignal, setCurrentBotMessage } from "../../redux/chatSlice";
import { EventLogRequest, PollMessage, SkuGroup, LogApiArg, useLogMutation } from "../../redux/georgebotApi";
import { addSlide } from "../product/carouselSlice";
import { useGetProductImageMutation } from "../../redux/backendSlice";
import { UIStateEnum } from "../../redux/types";


interface BotTextToSpeechProps {
    message: PollMessage;
    onComplete: () => void;
}

const BotTextToSpeech: React.FC<BotTextToSpeechProps> = ({ message, onComplete }) => {
    const [event, { data: eventData, error: eventError, isLoading: eventLoading }] = useLogMutation();
    const dispatch = useDispatch();
    const [getImageUrl] = useGetProductImageMutation();
    const chatState = useSelector((state: RootState) => state.chat.state);
    const uiState = useSelector((state: RootState) => state.ui.state);
    const audioContext = new (window.AudioContext)();
    const source = audioContext.createBufferSource();
    const [isPlaying, setIsPlaying] = React.useState(false);
    console.log("BotTextToSpeech.tsx: isPlaying: ", isPlaying);

    useEffect(() => {
        if (uiState === UIStateEnum.finished) {
            try {
                source.onended = null;
                source.stop();
            }
            catch (err) {
                console.log(`Failed to stop audio: ${err}`);
            }
            setIsPlaying(false);
        }
    }, [uiState]);

    const getProductImage = async (sku: string) => {
        return getImageUrl(sku).unwrap();
    }
    const addSkuGroupToCarousel = async (skuGroup: SkuGroup) => {
        const firstSku = Object.values(skuGroup.skus)[0].sku;
        const imageUrl = await getProductImage(firstSku);
        dispatch(addSlide({ product: skuGroup, imageUrl: imageUrl }));
    }

    const speakMessage = async (message: PollMessage) => {
        const REACT_APP_ENV_MODE = process.env.REACT_APP_ENV_MODE;
        let apiUrl = 'http://localhost:5000';
        if (REACT_APP_ENV_MODE === 'staging') {
            apiUrl = process.env.REACT_APP_STAGING_BACKEND_URL as string;
        }
        if (REACT_APP_ENV_MODE === 'production') {
            apiUrl = process.env.REACT_APP_PRODUCTION_BACKEND_URL as string;
        }
        fetch(`${apiUrl}/tts`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: `prompt=${message.text}&token=${localStorage.getItem("token")}`,

        }).then((response) => {
            const reader = response.body?.getReader();
            const stream = new ReadableStream({
                start(controller) {
                    const push = () => {
                        reader?.read().then(({ done, value }) => {
                            if (done) {
                                controller.close();
                                return;
                            }
                            controller.enqueue(value);
                            push();
                        });
                    }
                    push();
                }
            });
            return new Response(stream);
        })
            .then(response => response.arrayBuffer())
            .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
            .then(audioBuffer => {
                source.buffer = audioBuffer;
                source.connect(audioContext.destination);
                source.onended = () => {
                    if (message.end_signal === true) {
                        dispatch(setEndSignal(message.end_signal));
                        event({
                            "x-token": localStorage.getItem("token") as string,
                            eventLogRequest: {
                                event: "ai_end_signal_received",
                                cid: store.getState().chat.cid?.toString(),
                                data: {},
                            } as EventLogRequest
                        } as LogApiArg);
                    }
                    setIsPlaying(false);
                    onComplete();
                };
                window.addEventListener("userinterrupt", () => {
                    try {
                        source.onended = null;
                        source.stop();
                    }
                    catch (err) {
                        console.log(`Failed to stop audio: ${err}`);
                    }
                    setIsPlaying(false);
                });
                try {
                    dispatch(setSpeaking());
                    dispatch(setCurrentBotMessage(message));
                    source.start();
                    setIsPlaying(true);
                    if (message.sku_groups) {
                        const skuGroups = message.sku_groups;
                        skuGroups.forEach((skuGroup) => {
                            if (skuGroup.is_substitute) {
                                addSkuGroupToCarousel(skuGroup);
                            }
                        });
                        skuGroups.forEach((skuGroup) => {
                            if (!skuGroup.is_substitute) {
                                addSkuGroupToCarousel(skuGroup);
                            }
                        });
                    }
                }
                catch (err) {
                    console.log(`Failed to start audio: ${err}`);
                    event({
                        "x-token": localStorage.getItem("token") as string,
                        eventLogRequest: {
                            event: "kiosk_error",
                            cid: store.getState().chat.cid?.toString(),
                            data: { error: `Error in BotTextToSpeech.speakMessage While playing speech: ${err}` },
                        } as EventLogRequest
                    } as LogApiArg);
                }
            });
    }

    useEffect(() => {
        speakMessage(message);
    }, []);



    return null;
}

export default BotTextToSpeech;