import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import ProductCarousel from './product/ProductCarousel';
import { CarouselStateEnum } from '../redux/types';

const ProductWindow: React.FC = () => {
    const carouselState = useSelector((state: RootState) => state.carousel.state);

    return (
        <div className="flex flex-col h-[68vh] max-h-[68vh] px-8 pt-12 my-1">
            {/* <p className="font-sans drop-shadow-md text-lime-50 text-4xl">UI State: {uiState}</p> */}
            {/* <div className="drop-shadow-2xl w-auto h-auto"> */}
                <div className="flex justify-center drop-shadow-4xl items-center rounded-t-3xl bg-emerald-600 h-[10%] w-full">
                    <h1 className="text-[6vw] antialiased font-bold text-green-50">{carouselState === CarouselStateEnum.TOPPICKS ? 'TOP PICKS' : "ITEMS YOU'LL LOVE" }</h1>
                </div>
                <ProductCarousel />
            {/* </div> */}
        </div>
    );
};

export default ProductWindow;