import react, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { store, RootState } from "../redux/store";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { InitiateRequest, InitiateApiArg, PollMessage, useInitiateMutation } from '../redux/georgebotApi';
import { setCurrentBotMessageBuffer, setListening, setCurrentUserMessageInProgress, setCurrentUserMessage, setCid, clearCurrentUserMessageInProgress } from "../redux/chatSlice";
import { setStarting, setActive } from "../redux/uiSlice";
import { UIStateEnum } from '../redux/types';

// type InitiateRequest = components["schemas"]["InitiateRequest"];
// type PollMessage = components["schemas"]["PollMessage"];

const WakeWordListenerOpen: React.FC = () => {
    const [initiate, { data, isLoading }] = useInitiateMutation();
    const uiState = useSelector((state: RootState) => state.ui.state);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleInitiate = async (request: InitiateRequest, userMessage: string = '') => {
        const initResponse = await initiate({
            "x-token": localStorage.getItem("token") as string,
            initiateRequest: request} as InitiateApiArg).unwrap();
        let cid = initResponse.cid;
        const greetingMessage: PollMessage = {
            response_id: "1",
            index: 0,
            text: "Hello, how can I help you today?",
            sku_groups: [],
            end_signal: false,
        };
        dispatch(setCid(cid));

        if (userMessage !== '') {
            dispatch(setActive());
            dispatch(setListening());
            dispatch(setCurrentUserMessageInProgress(userMessage));
            dispatch(setCurrentUserMessage());

        }
        else {
            dispatch(setActive());
            dispatch(setCurrentBotMessageBuffer(greetingMessage as PollMessage));
        }
    }

    const handleWakeWord = (userMessage: string = "") => {
        console.log("Wakeword detected:", userMessage);
        const event = new CustomEvent("wakeword");
        const currentUiState = store.getState().ui.state;
        window.dispatchEvent(event);
        switch (currentUiState) {
            case UIStateEnum.idle:
                dispatch(clearCurrentUserMessageInProgress());
                dispatch(setStarting());
                try {
                    let store_num, kiosk_num;
                    let client_id = "";
                    if (localStorage.getItem("store_num")) {
                        store_num = parseInt(localStorage.getItem("store_num") as string);
                    }
                    else {
                        navigate("/auth");
                    }
                    if (localStorage.getItem("kiosk_num")) {
                        kiosk_num = parseInt(localStorage.getItem("kiosk_num") as string);
                    }
                    else {
                        navigate("/auth");
                    }
                    if (localStorage.getItem("client_id")) {
                        client_id = localStorage.getItem("client_id") as string;
                    }
                    else {
                        navigate("/auth");
                    }
                    const request: InitiateRequest = {
                        guest: true,
                        store_num: store_num,
                        kiosk_num: kiosk_num,
                        bottleclub_id: "",
                        client_id: client_id,
                        purpose: "chat",
                        greeting: "Hello, how can I help you today?",
                    }
                    handleInitiate(request, userMessage);
                }
                catch (error) {
                    console.error("Failed to initiate:", error);
                }
                break;
        }
    }

    const commands = [
        {
            command: "okay George *",
            callback: (userMessage: string) => handleWakeWord(userMessage),
        },
        {
            command: "okay George",
            callback: () => handleWakeWord(),
        }
    ];

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
        isMicrophoneAvailable,
    } = useSpeechRecognition({ commands });

    useEffect(() => {
        if (!browserSupportsSpeechRecognition) {
            console.log("Browser doesn't support speech recognition.");
            return;
        }

        // Start listening when the component mounts
        console.log("Listening for wake word...");
        SpeechRecognition.startListening({ continuous: true });

        // // Optionally add cleanup function to stop listening when component unmounts
        return () => {
          SpeechRecognition.stopListening();
        };
    }, []); // Empty dependency array ensures it only runs once

    useEffect(() => {
        // if (transcript !== "") {
        //     console.log("Transcript:", transcript);
        // }
    }, [transcript]);

    useEffect(() => {
        if (isMicrophoneAvailable === false) {
            console.log("Microphone not available.");
            throw "Microphone not available.";
        }
    }, [isMicrophoneAvailable]);


    return null;
};

export default WakeWordListenerOpen;