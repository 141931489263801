import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChatStateEnum, PollStateEnum } from "./types";
import { PollMessage, AiRunStatus } from "./georgebotApi";


interface ChatState {
    state: ChatStateEnum;
    cid?: string;
    run_id?: string;
    currentSystemMessage?: string;
    currentUserMessageInProgress: string;
    currentUserMessage: string;
    currentBotMessage?: BotMessage;
    currentBotMessageBuffer?: BotMessage;
    botMessageQueue: BotMessageQueue;
    botMessageHistory: string[];
    pollStatus?: AiRunStatus;
    end_signal: boolean;
    errorCount: number;
    muted: boolean;
}


interface BotMessage {
    message: PollMessage;
}

interface BotMessageQueue {
    messages: BotMessage[];
}

const initialState: ChatState = {
    state: ChatStateEnum.WAITING,
    cid: undefined,
    run_id: undefined,
    currentSystemMessage: undefined,
    currentUserMessageInProgress: "",
    currentUserMessage: "",
    currentBotMessage: undefined,
    currentBotMessageBuffer: undefined,
    botMessageQueue: { messages: [] },
    botMessageHistory: [],
    pollStatus: undefined,
    end_signal: false,
    errorCount: 0,
    muted: false,
};

const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        setWaiting: (state) => {
            state.state = ChatStateEnum.WAITING;
        },
        setListening: (state) => {
            state.state = ChatStateEnum.LISTENING;
        },
        setThinking: (state) => {
            state.state = ChatStateEnum.THINKING;
        },
        setPolling: (state) => {
            state.state = ChatStateEnum.POLLING;
        },
        setSpeaking: (state) => {
            state.state = ChatStateEnum.SPEAKING;
        },
        setInterrupting: (state) => {
            state.state = ChatStateEnum.INTERRUPTING;
        },
        setCid: (state, action) => {
            state.cid = action.payload;
        },
        setRunId: (state, action) => {
            state.run_id = action.payload;
        },
        setEndSignal: (state, action: PayloadAction<boolean>) => {
            state.end_signal = action.payload;
        },
        setCurrentSystemMessage: (state, action: PayloadAction<string>) => {
            state.currentSystemMessage = action.payload;
        },
        setCurrentUserMessageInProgress: (state, action: PayloadAction<string>) => {
            state.currentUserMessageInProgress += action.payload;
        },
        setCurrentUserMessage: (state) => {
            state.currentUserMessage = state.currentUserMessageInProgress!;
        },
        setCurrentBotMessage: (state, action : PayloadAction<PollMessage>) => {
            if (state.currentBotMessage?.message.response_id !== action.payload.response_id) {
                state.currentBotMessage = { message: action.payload };
            }
        },
        setCurrentBotMessageBuffer: (state, action : PayloadAction<PollMessage>) => {
            if (state.currentBotMessageBuffer?.message.response_id !== action.payload.response_id) {
                state.currentBotMessageBuffer = { message: action.payload };
            }
        },
        setPollStatus: (state, action) => {
            state.pollStatus = action.payload;
        },
        clearRunId: (state) => {
            state.run_id = undefined;
        },
        clearCid: (state) => {
            state.cid = undefined;
        },
        clearPollStatus: (state) => {
            state.pollStatus = undefined;
        },
        clearCurrentSystemMessage: (state) => {
            state.currentSystemMessage = undefined;
        },
        clearCurrentUserMessageInProgress: (state) => {
            state.currentUserMessageInProgress = "";
        },
        clearCurrentBotMessage: (state) => {
            state.currentBotMessage = undefined;
        },
        clearCurrentBotMessageBuffer: (state) => {
            state.currentBotMessageBuffer = undefined;
        },
        addBotMessageToQueue: (state, action: PayloadAction<PollMessage>) => {
            if (!state.botMessageQueue.messages.some((msg) => msg.message.response_id === action.payload.response_id)
            && !state.botMessageHistory.some((id) => id === action.payload.response_id)) {
                state.botMessageQueue.messages.push({ message: action.payload });
                state.botMessageHistory.push(action.payload.response_id);
            }
        },
        updateCurrentBotMessageFromQueue: (state) => {
            if (state.botMessageQueue.messages.length > 0) {
                // Select lowest index message and move it from queue to current message
                state.currentBotMessageBuffer = state.botMessageQueue.messages.shift();
            }
        },
        clearBotMessageQueue: (state) => {
            state.botMessageQueue.messages = [];
        },
        clearBotMessageHistory: (state) => {
            state.botMessageHistory = [];
        },
        resetErrorCount: (state) => {
            state.errorCount = 0;
        },
        incrementErrorCount: (state) => {
            state.errorCount++;
        },
        mute: (state) => {
            state.muted = true;
        },
        unmute: (state) => {
            state.muted = false;
        },
        resetChat: (state) => {
            state.state = ChatStateEnum.WAITING;
            state.run_id = undefined;
            state.currentSystemMessage = undefined;
            state.currentUserMessageInProgress = "";
            state.currentUserMessage = "";
            state.currentBotMessage = undefined;
            state.currentBotMessageBuffer = undefined;
            state.botMessageQueue.messages = [];
            state.botMessageHistory = [];
            state.pollStatus = undefined;
            state.end_signal = false;
            state.errorCount = 0;
            state.muted = false;
        }
    },
});

export const { setWaiting,
    setListening,
    setThinking,
    setPolling,
    setInterrupting,
    setPollStatus,
    setRunId,
    setSpeaking,
    setCid,
    setCurrentSystemMessage,
    setCurrentBotMessage,
    setCurrentBotMessageBuffer,
    setCurrentUserMessageInProgress,
    setCurrentUserMessage,
    setEndSignal,
    clearCid,
    clearRunId,
    clearPollStatus,
    clearCurrentBotMessage,
    clearCurrentBotMessageBuffer,
    clearBotMessageQueue,
    clearCurrentUserMessageInProgress,
    clearCurrentSystemMessage,
    addBotMessageToQueue,
    resetErrorCount,
    incrementErrorCount,
    updateCurrentBotMessageFromQueue,
    mute,
    unmute,
    resetChat } = chatSlice.actions;
export default chatSlice.reducer;