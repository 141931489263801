import { createSlice } from "@reduxjs/toolkit";
import { UIStateEnum } from "./types";


interface UIState {
    state: UIStateEnum;
    showPopup: boolean;
}

const initialState: UIState = {
    state: UIStateEnum.idle,
    showPopup: false,
};

const uiSlice = createSlice({
    name: "ui",
    initialState,
    reducers: {
        setIdle: (state) => {
            state.state = UIStateEnum.idle;
        },
        setStarting: (state) => {
            if (state.state === UIStateEnum.idle) {
                state.state = UIStateEnum.starting;
            }
        },
        setActive: (state) => {
            state.state = UIStateEnum.active;
        },
        setFinished: (state) => {
            state.state = UIStateEnum.finished;
        },
        showPopup: (state) => {
            state.showPopup = true;
        },
        hidePopup: (state) => {
            state.showPopup = false;
        },
    },
});

export const { setIdle, setStarting, setActive, setFinished, showPopup, hidePopup } = uiSlice.actions;
export default uiSlice.reducer;