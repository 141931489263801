export interface AuthState {
    token: string | null;
    store_num: number | null;
    kiosk_num: number | null;
    client_id: string | null;
}

export enum UIStateEnum {
    idle = "idle",
    starting = "starting",
    active = "active",
    finished = "finished",
    finalizing = "finalizing",
    error = "error",
}

export enum ChatStateEnum {
    WAITING = "waiting",
    LISTENING = "listening",
    THINKING = "thinking",
    POLLING = "polling",
    SPEAKING = "speaking",
    INTERRUPTING = "interrupting",
    ERROR = "error",
}

export enum PollStateEnum {
    ERROR = "error",
    CREATED = "created",
    PENDING = "pending",
    STREAMING = "streaming",
    COMPLETED = "completed",
    TERMINATED = "terminated",
    INTERRUPTED = "interrupted",
}

export enum CarouselStateEnum {
    TOPPICKS = "toppicks",
    RECOMMENDED = "recommended",
    LOADING = "loading",
}