import React, { useEffect, useRef } from "react";

type BotSpeechBubbleProps = {
    message: string;
};

const BotSpeechBubble: React.FC<BotSpeechBubbleProps> = ({ message }) => {
    const botTextRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const divElement = botTextRef.current;
        if (!divElement) return;

        let scrollPosition = 0;
        const scrollSpeed = 1;
        const intervalTime = 40;

        const scrollTimeout = setTimeout(() => {
            const scrollInterval = setInterval(() => {
                if (divElement.scrollTop < divElement.scrollHeight - divElement.clientHeight) {
                    scrollPosition += scrollSpeed;
                    divElement.scrollTop = scrollPosition;
                }
                else {
                    clearInterval(scrollInterval);
                }
            }, intervalTime);
            divElement.addEventListener("click", () => {
                clearInterval(scrollInterval);
            });
        }, 2000);

        return () => {
            clearTimeout(scrollTimeout);
        };
    }, []);

    return (
        <div
        id="bot-speech-bubble" 
        className={`relative z-50 -translate-x-[2vw] -translate-y-[2vh] w-[60vw] max-w-[75vw] max-h-[33%] gap-2.5 drop-shadow-3xl`
        }>
            <div ref={botTextRef} 
                className={`absolute bottom-0 px-16 w-[70vw] max-w-[75vw] max-h-[15vh] leading-1.5 p-4 border-gray-200 bg-gray-100 rounded-tl-3xl rounded-e-3xl  dark:bg-gray-700 overflow-scroll`}>
                <p id="current-bot-message" className="text-[4vw] font-sans px-4 py-4 text-gray-900 dark:text-white">
                    {message}
                </p>
            </div>
        </div>
    );
};

export default BotSpeechBubble;