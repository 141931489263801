import React, { memo, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../redux/store";
import BotSpeechBubble from "./BotSpeechBubble";
import UserTextBox from "./UserTextBox";
import { ChatStateEnum, UIStateEnum } from "../../redux/types";
import BotThinkingBubble from "./BotThinkingBubble";
import {
    clearRunId,
    clearCurrentBotMessage,
    clearCurrentBotMessageBuffer,
    clearBotMessageQueue,
    clearCurrentUserMessageInProgress,
    setCurrentUserMessage,
    setListening,
    mute,
    unmute,
} from "../../redux/chatSlice";
import { EventLogRequest, LogApiArg, useLogMutation } from "../../redux/georgebotApi";
import { setFinished } from "../../redux/uiSlice";

// Display the bot speech bubble and the user text box in a dialog view
// They are displayed one on top of the other

const DialogView: React.FC = () => {
    const [event, { data: eventData, error: eventError, isLoading: eventLoading }] = useLogMutation();
    const dispatch = useDispatch();
    const muteButtonRef = useRef<HTMLButtonElement>(null);
    const interruptButtonRef = useRef<HTMLButtonElement>(null);
    const terminateButtonRef = useRef<HTMLButtonElement>(null);
    const isMuted = useSelector((state: RootState) => state.chat.muted);
    const chatState = useSelector((state: RootState) => state.chat.state);
    const currentBotMessage = useSelector((state: RootState) => state.chat.currentBotMessage);
    const [botSpeechBubbleFadingOut, setBotSpeechBubbleFadingOut] = useState(false);
    const [showBotSpeechBubble, setShowBotSpeechBubble] = useState(false);
    const [showBotThinkingBubble, setShowBotThinkingBubble] = useState(false);
    const [interruptButtonVisible, setInterruptButtonVisible] = useState(false);


    const handleMute = () => {
        const isMuted = store.getState().chat.muted;
        if (isMuted === true) {
            dispatch(unmute());
            const muteEvent = new CustomEvent("speechResume");
            window.dispatchEvent(muteEvent);
        }
        else {
            dispatch(mute());
            const unmuteEvent = new CustomEvent("speechPause");
            window.dispatchEvent(unmuteEvent);
        }
    };

    const handleInterrupt = () => {
        console.log("Interrupting...");
        const interruptEvent = new CustomEvent("userinterrupt");
        window.dispatchEvent(interruptEvent);
        dispatch(clearBotMessageQueue());
        dispatch(clearCurrentBotMessage());
        dispatch(setListening());
        dispatch(clearRunId());
        dispatch(clearCurrentBotMessageBuffer());
        dispatch(clearCurrentUserMessageInProgress());
        dispatch(setCurrentUserMessage());
        event({
            "x-token": localStorage.getItem("token") as string,
            eventLogRequest: { 
            cid: store.getState().chat.cid?.toString(),
            event: "user_interrupt_touch",
            data: {},
        } as EventLogRequest
        } as LogApiArg);
    };

    const handleTerminate = () => {
        console.log("Terminating...");
        const currentUiState = store.getState().ui.state;
        if (currentUiState === UIStateEnum.active) {
            const interruptEvent = new CustomEvent("userinterrupt");
            window.dispatchEvent(interruptEvent);
            dispatch(setFinished());
        }
    };

    useEffect(() => {
        if (currentBotMessage === undefined) {
            if ([ChatStateEnum.POLLING, ChatStateEnum.THINKING].includes(chatState))
                setShowBotThinkingBubble(true);
            else
                setShowBotThinkingBubble(false);
        }
        else {
            setShowBotThinkingBubble(false);
        }
    }, [currentBotMessage, chatState]);

    useEffect(() => {
        if ([ChatStateEnum.SPEAKING].includes(chatState)) {
            setInterruptButtonVisible(true);
            setBotSpeechBubbleFadingOut(false);
            setShowBotSpeechBubble(true);
        } else if ([ChatStateEnum.LISTENING, ChatStateEnum.THINKING, ChatStateEnum.POLLING].includes(chatState) && showBotSpeechBubble) {
            setBotSpeechBubbleFadingOut(true);
            setInterruptButtonVisible(false);
            const timeout = setTimeout(() => {
                setShowBotSpeechBubble(false);
            }, 600);
            return () => clearTimeout(timeout);
        }

    }, [chatState]);

    return (
        <div className={`flex-row max-w-[75vw]`}>
            <span
                className={`transition-opacity duration-600 ease-in-out ${botSpeechBubbleFadingOut ? "opacity-0" : "opacity-100"
                    }`}>{showBotSpeechBubble && currentBotMessage !== undefined && <BotSpeechBubble message={currentBotMessage.message.text} />}
            </span>
            <span>
                <button ref={muteButtonRef} className={`translate-x-[4vw] ${isMuted ? 'bg-rose-700' : 'bg-sky-700'} text-white font-semibold text-[3vw] py-2 px-4 rounded-3xl w-fit`}
                    onClick={() => handleMute()}
                >{isMuted ? '🔇' : '🔊'}</button>
            </span>
            <span>
                <button ref={terminateButtonRef} className={`translate-x-[38vw] bg-sky-700 hover:bg-rose-700 text-white font-semibold text-[3vw] py-2 px-4 rounded-3xl w-fit`}
                    onClick={handleTerminate}
                >End & QR Code</button>
            </span>
            <span>
                <button ref={interruptButtonRef} className={`-translate-x-[2vw] bg-rose-600 hover:bg-rose-700 text-white font-semibold text-[3vw] py-2 px-4 rounded-3xl w-fit
                     transition-opacity duration-600 ease-in-out ${interruptButtonVisible ? "opacity-100" : "opacity-0"}`}
                    onClick={handleInterrupt}
                >Interrupt</button>
            </span>
            <span>{showBotThinkingBubble && <BotThinkingBubble />}</span>
            <span><UserTextBox /></span>
        </div>
    );
};

export default DialogView;