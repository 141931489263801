import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { clear } from "console";
import { AuthState } from "./types";


const tokenFromStorage = localStorage.getItem("token");
const storeNumFromStorage = localStorage.getItem("store_num");
const kioskNumFromStorage = localStorage.getItem("kiosk_num");
const clientIdFromStorage = localStorage.getItem("client_id");

const initialState: AuthState = {
    token: tokenFromStorage ? tokenFromStorage : null,
    store_num: storeNumFromStorage ? parseInt(storeNumFromStorage) : null,
    kiosk_num: kioskNumFromStorage ? parseInt(kioskNumFromStorage) : null,
    client_id: clientIdFromStorage ? clientIdFromStorage : null,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<AuthState>) => {
            state.token = action.payload.token;
            state.store_num = action.payload.store_num;
            state.kiosk_num = action.payload.kiosk_num;
            state.client_id = action.payload.client_id;
            if (action.payload.token && action.payload.store_num && action.payload.kiosk_num && action.payload.client_id) {
                localStorage.setItem("token", action.payload.token);
                localStorage.setItem("store_num", action.payload.store_num.toString());
                localStorage.setItem("kiosk_num", action.payload.kiosk_num.toString());
                localStorage.setItem("client_id", action.payload.client_id);
            }
            else {
                localStorage.removeItem("token");
                localStorage.removeItem("store_num");
                localStorage.removeItem("kiosk_num");
                localStorage.removeItem("client_id");
            }
            
        },
        clearToken: (state) => {
            state.token = null;
            state.store_num = null;
            state.kiosk_num = null;
            state.client_id = null;
        },
    },
});

export const { setToken, clearToken } = authSlice.actions;

export default authSlice.reducer;