import React from 'react';


const InactivityPopup: React.FC = () => {
    return (
        <div className={`absolute drop-shadow-2xl mt-[2vh]  p-20 z-50 bg-slate-300 opacity-85 w-[94vw] h-[95vh] rounded-3xl`}>
            <div className="flex flex-auto">
                <h1 className="flex align-middle justify-center text-[6vw] font-semibold text-center">Are you still there? Tap or say "OK George" to continue.</h1>
            </div>
        </div>
    );
};

export default InactivityPopup;