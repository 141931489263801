import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, store } from '../redux/store';
import { setIdle } from '../redux/uiSlice';
import { clearCurrentUserMessageInProgress, setWaiting, resetErrorCount, resetChat } from '../redux/chatSlice';
import { setSlidesToTopPicks } from '../components/product/carouselSlice';
import { clearCid, setCurrentUserMessageInProgress } from '../redux/chatSlice';
import './HomePage.css';
import IntroMessage from '../components/IntroMessage';
import ChatWindow from '../components/ChatWindow';
import ProductWindow from '../components/ProductWindow';
import FinishedWindow from '../components/FinishedWindow';
import InactivityTimer from '../components/chat/InactivityTimer';
import InactivityPopup from '../components/chat/InactivityPopup';
// import WakeWordListener from '../components/WakeWordListener';
import WakeWordListenerOpen from '../components/WakeWordListenerOpen';
import { ChatStateEnum, UIStateEnum } from '../redux/types';


const HomePage: React.FC = () => {
    const dispatch = useDispatch();
    const runId = useSelector((state: RootState) => state.chat.run_id);
    const uiState = useSelector((state: RootState) => state.ui.state);
    const chatState = useSelector((state: RootState) => state.chat.state);
    const cid = useSelector((state: RootState) => state.chat.cid);
    const showPopup = useSelector((state: RootState) => state.ui.showPopup);

    const handleResetChat = () => {
        const currentUiState = store.getState().ui.state;
        if (currentUiState === UIStateEnum.finished) {
            dispatch(setIdle());
        }
    };

    useEffect(() => {
        if (uiState === UIStateEnum.finished) {
            // Reset to idle after 15 seconds
            dispatch(resetChat());
            dispatch(setSlidesToTopPicks());
            const speechPauseEvent = new CustomEvent("speechPause");
            window.dispatchEvent(speechPauseEvent);
        }
        if (uiState === UIStateEnum.idle) {
            // Clear CID when idle
            dispatch(clearCid());
        }
    }, [uiState]);


    return (
        <>
            <div id="home-page" className="flex flex-col h-screen max-h-screen px-8 bg-store bg-cover">
                <div className={`flex-1 transition-opacity duration-300 ${uiState != "idle" ? 'opacity-0' : ''
                    }`}>
                    <IntroMessage />
                </div>
                 <ChatWindow cid={cid} />
                <div className="rounded-2xl">
                    <ProductWindow />
                </div>
                    {showPopup && <InactivityPopup />}
                    {uiState === UIStateEnum.finished && cid && <FinishedWindow cid={cid} onComplete={handleResetChat}/>}
            </div>
            {uiState === UIStateEnum.active && <InactivityTimer />}
            {(uiState === UIStateEnum.idle || chatState === ChatStateEnum.SPEAKING || showPopup) && <WakeWordListenerOpen />}
        </>
    );
};

export default HomePage;