import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../redux/store";
import { setCurrentUserMessageInProgress, setCurrentUserMessage } from "../../redux/chatSlice";
import { ChatStateEnum, UIStateEnum } from "../../redux/types";
import { LogApiArg, EventLogRequest, useLogMutation } from "../../redux/georgebotApi";

const UserSpeechToText: React.FC = React.memo(() => {
    const [logEvent, { data: eventData, error: eventError, isLoading: eventLoading }] = useLogMutation();
    const dispatch = useDispatch();
    const uiState = useSelector((state: RootState) => state.ui.state);
    const chatState = useSelector((state: RootState) => state.chat.state);
    const randomId = Math.random().toString(36).substring(7);
    let listenTimeout: NodeJS.Timeout;

    const handleSpeechStart = useCallback(async (event: any) => {
        clearTimeout(listenTimeout);
        console.log(`Speech Start: ${randomId}`);
    }, []);

    const handleSpeechEnd = useCallback(async (event: any) => {
        const REACT_APP_ENV_MODE = process.env.REACT_APP_ENV_MODE;
        let apiUrl = 'http://localhost:5000';
        if (REACT_APP_ENV_MODE === 'staging') {
            apiUrl = process.env.REACT_APP_STAGING_BACKEND_URL as string;
        }
        if (REACT_APP_ENV_MODE === 'production') {
            apiUrl = process.env.REACT_APP_PRODUCTION_BACKEND_URL as string;
        }
        console.log(`Speech End: ${randomId}`);
        const audio: Float32Array = event.detail.audio;
        const buffer = audio.buffer;
        const currentUiState = store.getState().ui.state;
        if (currentUiState === UIStateEnum.finished || currentUiState === UIStateEnum.idle) {
            return;
        }
        const response = await fetch(`${apiUrl}/stt`, {
            method: "POST",
            headers: {
                "Content-Type": "application/octet-stream",
                "x-token": localStorage.getItem("token") || "",
                "cid": store.getState().chat.cid?.toString() || "",
            },
            body: buffer,

        }).catch((error) => {
            console.error("Error:", error);
            logEvent({
                "x-token": localStorage.getItem("token") as string,
                eventLogRequest: {
                    cid: store.getState().chat.cid?.toString(),
                    event: "kiosk_error",
                    data: { error: error },
                } as EventLogRequest
            } as LogApiArg);
        }
        );
        if (response !== undefined) {
            const data = await response.json();
            const text = data.text;
            console.log("STT Response:", text);
            if (text === "" || text === " ") {
                return;
            }
            dispatch(setCurrentUserMessageInProgress(`${text} `));
            listenTimeout = setTimeout(() => {
                console.log("Listen Timeout");
                dispatch(setCurrentUserMessage());
                logEvent({
                    "x-token": localStorage.getItem("token") as string,
                    eventLogRequest: {
                        cid: store.getState().chat.cid?.toString(),
                        event: "user_send_voice",
                        data: { text: text },
                    } as EventLogRequest
                } as LogApiArg);
            }, 2500);
        }
    }, []);

    useEffect(() => {
        if (uiState === UIStateEnum.active && chatState === ChatStateEnum.LISTENING && store.getState().chat.muted !== true) {
            const event = new CustomEvent("speechResume");
            window.dispatchEvent(event);
        }
        else {
            const event = new CustomEvent("speechPause");
            window.dispatchEvent(event);
        }
    }, [uiState, chatState]);

    useEffect(() => {
        console.log(`UserSpeechToText: ${randomId}`);
        console.log("Adding event listeners");
        // Add event listeners for speech start and end if they don't already exist
        window.addEventListener("speechStart", handleSpeechStart);
        window.addEventListener("speechEnd", handleSpeechEnd);

        return () => {
            console.log("Removing event listeners");
            window.removeEventListener("speechStart", handleSpeechStart);
            window.removeEventListener("speechEnd", handleSpeechEnd);
        }
    }, [handleSpeechStart, handleSpeechEnd]);

    return null;
});

export default UserSpeechToText;