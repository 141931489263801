import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    initiate: build.mutation<InitiateApiResponse, InitiateApiArg>({
      query: (queryArg) => ({
        url: `/initiate`,
        method: "POST",
        body: queryArg.initiateRequest,
        headers: { "x-token": queryArg["x-token"] },
      }),
    }),
    send: build.mutation<SendApiResponse, SendApiArg>({
      query: (queryArg) => ({
        url: `/send`,
        method: "POST",
        body: queryArg.sendRequest,
        headers: { "x-token": queryArg["x-token"] },
      }),
    }),
    stream: build.mutation<StreamApiResponse, StreamApiArg>({
      query: (queryArg) => ({
        url: `/stream`,
        method: "POST",
        body: queryArg.streamRequest,
        headers: { "x-token": queryArg["x-token"] },
      }),
    }),
    poll: build.query<PollApiResponse, PollApiArg>({
      query: (queryArg) => ({
        url: `/poll`,
        headers: { "x-token": queryArg["x-token"] },
        params: {
          cid: queryArg.cid,
          run_id: queryArg.runId,
          interrupt: queryArg.interrupt,
          terminate: queryArg.terminate,
        },
      }),
    }),
    feedback: build.mutation<FeedbackApiResponse, FeedbackApiArg>({
      query: (queryArg) => ({
        url: `/feedback`,
        method: "POST",
        body: queryArg.feedbackRequest,
        headers: { "x-token": queryArg["x-token"] },
      }),
    }),
    review: build.mutation<ReviewApiResponse, ReviewApiArg>({
      query: (queryArg) => ({
        url: `/review`,
        method: "POST",
        body: queryArg.reviewRequest,
        headers: { "x-token": queryArg["x-token"] },
      }),
    }),
    terminate: build.mutation<TerminateApiResponse, TerminateApiArg>({
      query: (queryArg) => ({
        url: `/terminate`,
        method: "POST",
        body: queryArg.terminateRequest,
        headers: { "x-token": queryArg["x-token"] },
      }),
    }),
    stt: build.mutation<SttApiResponse, SttApiArg>({
      query: (queryArg) => ({
        url: `/stt`,
        method: "POST",
        body: queryArg.bodyStt,
        headers: { "x-token": queryArg["x-token"] },
      }),
    }),
    tts: build.mutation<TtsApiResponse, TtsApiArg>({
      query: (queryArg) => ({
        url: `/tts`,
        method: "POST",
        body: queryArg.ttsRequest,
        headers: { "x-token": queryArg["x-token"] },
      }),
    }),
    topPicks: build.mutation<TopPicksApiResponse, TopPicksApiArg>({
      query: (queryArg) => ({
        url: `/top-picks`,
        method: "POST",
        body: queryArg.topPicksRequest,
        headers: { "x-token": queryArg["x-token"] },
      }),
    }),
    log: build.mutation<LogApiResponse, LogApiArg>({
      query: (queryArg) => ({
        url: `/events/log`,
        method: "POST",
        body: queryArg.eventLogRequest,
        headers: {
          "x-conversation-key": queryArg["x-conversation-key"],
          "x-token": queryArg["x-token"],
        },
      }),
    }),
    cartAdd: build.mutation<CartAddApiResponse, CartAddApiArg>({
      query: (queryArg) => ({
        url: `/cart/add`,
        method: "POST",
        body: queryArg.cartAddRequest,
        headers: { "x-token": queryArg["x-token"] },
      }),
    }),
    cartRemove: build.mutation<CartRemoveApiResponse, CartRemoveApiArg>({
      query: (queryArg) => ({
        url: `/cart/remove`,
        method: "POST",
        body: queryArg.cartRemoveRequest,
        headers: { "x-token": queryArg["x-token"] },
      }),
    }),
    cartCheckout: build.mutation<CartCheckoutApiResponse, CartCheckoutApiArg>({
      query: (queryArg) => ({
        url: `/cart/checkout`,
        method: "POST",
        body: queryArg.cartCheckoutRequest,
        headers: { "x-token": queryArg["x-token"] },
      }),
    }),
    uploadVideo: build.mutation<UploadVideoApiResponse, UploadVideoApiArg>({
      query: (queryArg) => ({
        url: `/video/upload/`,
        method: "POST",
        body: queryArg.bodyUploadVideo,
      }),
    }),
    summarize: build.mutation<SummarizeApiResponse, SummarizeApiArg>({
      query: (queryArg) => ({
        url: `/conversation/summarize`,
        method: "POST",
        body: queryArg.summarizeRequest,
        headers: { "x-conversation-key": queryArg["x-conversation-key"] },
      }),
    }),
    products: build.mutation<ProductsApiResponse, ProductsApiArg>({
      query: (queryArg) => ({
        url: `/conversation/products`,
        method: "POST",
        body: queryArg.conversationProductsRequest,
        headers: { "x-conversation-key": queryArg["x-conversation-key"] },
      }),
    }),
    substitute: build.mutation<SubstituteApiResponse, SubstituteApiArg>({
      query: (queryArg) => ({
        url: `/conversation/substitute`,
        method: "POST",
        body: queryArg.substituteRequest,
        headers: { "x-conversation-key": queryArg["x-conversation-key"] },
      }),
    }),
    healthcheck: build.query<HealthcheckApiResponse, HealthcheckApiArg>({
      query: () => ({ url: `/healthcheck` }),
    }),
    auth: build.mutation<AuthApiResponse, AuthApiArg>({
      query: (queryArg) => ({
        url: `/auth`,
        method: "POST",
        body: queryArg.authRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as georgebotApi };
export type InitiateApiResponse =
  /** status 200 Successful Response */ InitiateResponse;
export type InitiateApiArg = {
  "x-token": string;
  initiateRequest: InitiateRequest;
};
export type SendApiResponse =
  /** status 200 Successful Response */ SendResponse;
export type SendApiArg = {
  "x-token": string;
  sendRequest: SendRequest;
};
export type StreamApiResponse =
  /** status 200 Successful Response */ StreamResponse;
export type StreamApiArg = {
  "x-token": string;
  streamRequest: StreamRequest;
};
export type PollApiResponse =
  /** status 200 Successful Response */ PollResponse;
export type PollApiArg = {
  cid: string;
  runId: string;
  interrupt?: boolean;
  terminate?: boolean;
  "x-token": string;
};
export type FeedbackApiResponse =
  /** status 200 Successful Response */ FeedbackResponse;
export type FeedbackApiArg = {
  "x-token": string;
  feedbackRequest: FeedbackRequest;
};
export type ReviewApiResponse =
  /** status 200 Successful Response */ ReviewResponse;
export type ReviewApiArg = {
  "x-token": string;
  reviewRequest: ReviewRequest;
};
export type TerminateApiResponse =
  /** status 200 Successful Response */ TerminateResponse;
export type TerminateApiArg = {
  "x-token": string;
  terminateRequest: TerminateRequest;
};
export type SttApiResponse = /** status 200 Successful Response */ SttResponse;
export type SttApiArg = {
  "x-token": string;
  bodyStt: BodyStt;
};
export type TtsApiResponse = /** status 200 Successful Response */ any;
export type TtsApiArg = {
  "x-token": string;
  ttsRequest: TtsRequest;
};
export type TopPicksApiResponse =
  /** status 200 Successful Response */ TopPicksResponse;
export type TopPicksApiArg = {
  "x-token": string;
  topPicksRequest: TopPicksRequest;
};
export type LogApiResponse =
  /** status 200 Successful Response */ EventLogResponse;
export type LogApiArg = {
  "x-conversation-key"?: string;
  "x-token"?: string;
  eventLogRequest: EventLogRequest;
};
export type CartAddApiResponse =
  /** status 200 Successful Response */ CartAddResponse;
export type CartAddApiArg = {
  "x-token": string;
  cartAddRequest: CartAddRequest;
};
export type CartRemoveApiResponse =
  /** status 200 Successful Response */ CartRemoveResponse;
export type CartRemoveApiArg = {
  "x-token": string;
  cartRemoveRequest: CartRemoveRequest;
};
export type CartCheckoutApiResponse =
  /** status 200 Successful Response */ CartCheckoutResponse;
export type CartCheckoutApiArg = {
  "x-token": string;
  cartCheckoutRequest: CartCheckoutRequest;
};
export type UploadVideoApiResponse =
  /** status 200 Successful Response */ VideoUploadResponse;
export type UploadVideoApiArg = {
  bodyUploadVideo: BodyUploadVideo;
};
export type SummarizeApiResponse =
  /** status 200 Successful Response */ SummarizeResponse;
export type SummarizeApiArg = {
  "x-conversation-key": string;
  summarizeRequest: SummarizeRequest;
};
export type ProductsApiResponse =
  /** status 200 Successful Response */ ConversationProductsResponse;
export type ProductsApiArg = {
  "x-conversation-key": string;
  conversationProductsRequest: ConversationProductsRequest;
};
export type SubstituteApiResponse =
  /** status 200 Successful Response */ SubstituteResponse;
export type SubstituteApiArg = {
  "x-conversation-key": string;
  substituteRequest: SubstituteRequest;
};
export type HealthcheckApiResponse =
  /** status 200 Successful Response */ HealthCheckResponse;
export type HealthcheckApiArg = void;
export type AuthApiResponse =
  /** status 200 Successful Response */ AuthResponse;
export type AuthApiArg = {
  authRequest: AuthRequest;
};
export type InitiateResponse = {
  /** The id for this conversation */
  cid: string;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type InitiateRequest = {
  /** Whether the user is a guest */
  guest?: boolean;
  /** The store number for this kiosk or request. Optional if web request */
  store_num?: number | null;
  /** The kiosk number for this request. Optional if web request */
  kiosk_num?: number | null;
  /** The bottleclub id for this user. Optional if user is a guest */
  bottleclub_id?: string | null;
  /** The ID for the client making the request */
  client_id: string;
  /** The purpose (if any) for this request */
  purpose?: string | null;
  /** The greeting message for this conversation */
  greeting?: string | null;
};
export type SkuDetail = {
  /** The SKU for the product */
  sku: string;
  /** The group id for the SKU group */
  group_id: number;
  /** The sgid for the SKU group */
  sgid: number;
  /** The display name for the product */
  display_name: string;
  /** The brand for the product */
  brand: string;
  /** The description for the product */
  description: string;
  /** Alcohol by volume */
  alcohol_by_volume: number;
  /** The size of the product */
  size: string;
  /** The bottle club points for the product */
  bottle_club_points: number;
  /** The type of the product */
  type: string;
  /** Whether the product is a preferred product */
  preferred_product: boolean;
  /** The quantity in stock for the product */
  qty_in_stock: number;
  /** The list price for the product */
  list_price: number | null;
  /** The sale price for the product */
  sale_price: number | null;
  /** The location of the product in the store */
  location: string | null;
};
export type SkuGroup = {
  /** The group id for the SKU group */
  group_id: number;
  /** The sgid for the SKU group */
  sgid: number;
  /** The display name for the product */
  display_name: string;
  /** The brand for the product */
  brand: string;
  /** The description for the product */
  description: string;
  /** Alcohol by volume */
  alcohol_by_volume: number;
  /** The type of the product */
  type: string;
  /** Whether the SKUGroup was created as a substitute. */
  is_substitute?: boolean;
  /** A mapping of SKU to SKU details */
  skus: {
    [key: string]: SkuDetail;
  };
};
export type SendResponse = {
  /** The id for this chatbot response */
  response_id: string;
  /** The response from the chatbot */
  response: string;
  /** The detailed information about the referenced SKUs, along with others in the same group. */
  sku_groups?: SkuGroup[];
  /** Whether the AI has determined that the conversation has ended. */
  end_signal?: boolean;
};
export type SendRequest = {
  /** The id for this conversation */
  cid: string;
  /** The text submitted by the user. */
  text: string;
  /** Whether the message was recorded from speech or not. */
  speech?: boolean;
};
export type StreamResponse = {
  /** The id to poll for updates on model output */
  run_id: string;
};
export type StreamRequest = {
  /** The id for this conversation */
  cid: string;
  /** The text submitted by the user. */
  text: string;
  /** Whether the message was recorded from speech or not. */
  speech?: boolean;
};
export type AiRunStatus =
  | "created"
  | "pending"
  | "streaming"
  | "completed"
  | "error"
  | "terminated"
  | "interrupted";
export type PollMessage = {
  /** The id for this chatbot response */
  response_id: string;
  /** The index of this message in the conversation */
  index: number;
  /** The response from the chatbot */
  text: string;
  /** The detailed information about the referenced SKUs, along with others in the same group. */
  sku_groups?: SkuGroup[];
  /** Whether the AI has determined that the conversation has ended. */
  end_signal?: boolean;
};
export type PollResponse = {
  /** The status of the model run */
  status: AiRunStatus;
  /** The messages from the AI model */
  messages?: PollMessage[];
};
export type FeedbackResponse = {
  /** True if the feedback was successfully uploaded */
  success?: boolean;
};
export type FeedbackRequest = {
  /** The id for this conversation */
  cid: string;
  /** The id of the relevent response to give feedback for */
  response_id: string;
  /** Whether the message reviewed positively or not. 1 for positive, -1 for negative, 0 for neutral. */
  review: number;
};
export type ReviewResponse = {
  /** True if the review was successfully uploaded */
  success?: boolean;
};
export type ReviewRequest = {
  /** The id for this conversation */
  cid: string;
  /** Whether the message reviewed positively or not. 1 for positive, -1 for negative, 0 for neutral. */
  rating: number;
  /** The review for this response */
  review?: string | null;
};
export type TerminateResponse = {
  /** True if the conversation was successfully terminated */
  success?: boolean;
};
export type TerminateRequest = {
  /** The id for this conversation */
  cid: string;
  /** How the conversation was terminated. */
  method: string;
};
export type SttResponse = {
  /** The text converted from the audio file. */
  text: string;
};
export type BodyStt = {
  /** Wav Formatted File */
  file: Blob;
};
export type TtsRequest = {
  /** The text to convert to speech. */
  text: string;
  /** The voice to use for the speech. */
  voice?: "alloy" | "echo" | "fable" | "onyx" | "nova" | "shimmer";
  /** Whether to load the entire file from openai before streaming. Helps reduce gaps inside the spoken stream, but increases initial latency. */
  load_upfront?: boolean;
};
export type TopPicksResponse = {
  /** The detailed information about the top picks. */
  sku_groups?: SkuGroup[];
};
export type TopPicksRequest = {
  /** The ID for the client making the request */
  client_id: string;
  /** The number of top picks to return */
  limit?: number;
  /** The store number for this kiosk or request */
  store_num: number;
  /** The mode for the top picks request */
  mode?: "all" | "wine" | "spirits";
};
export type EventLogResponse = {
  /** True if the event was successfully logged */
  success?: boolean;
};
export type Event =
  | "chat_page_loaded"
  | "feedback_page_loaded"
  | "thankyou_page_loaded"
  | "user_initiate_touch"
  | "user_initiate_voice"
  | "user_send_touch"
  | "user_send_voice"
  | "user_feedback"
  | "user_review_submit"
  | "user_interrupt_touch"
  | "user_review_voice_recording"
  | "user_terminate"
  | "user_product_view_swipe"
  | "user_product_view_similar_products"
  | "user_product_view_details"
  | "user_product_view_map"
  | "user_qr_code_scan"
  | "inactivity_timer_shown"
  | "inactivity_timer_dismissed"
  | "inactivity_timer_finished"
  | "ai_end_signal_received"
  | "kiosk_error"
  | "video_save_success"
  | "video_save_failure"
  | "georgebot_web_product_page_error"
  | "georgebot_web_summary_page_error"
  | "georgebot_web_user_click_main_product"
  | "georgebot_web_user_click_budget_product"
  | "georgebot_web_user_click_competitive_product"
  | "georgebot_web_user_click_premium_product"
  | "georgebot_web_user_click_external_link";
export type EventLogRequest = {
  /** The event to log */
  event: Event;
  /** The id for this conversation */
  cid?: string | null;
  /** Any additional data to log with the event */
  data?: {
    [key: string]: string;
  };
};
export type CartAddResponse = {
  /** True if the item was successfully added */
  success?: boolean;
};
export type CartAddRequest = {
  /** The id for this conversation */
  cid: string;
  /** The id of the product to add */
  product_id: number;
  /** The quantity of product to add to the cart */
  quantity?: number;
};
export type CartRemoveResponse = {
  /** True if the item was successfully removed */
  success?: boolean;
};
export type CartRemoveRequest = {
  /** The id for this conversation */
  cid: string;
  /** The id of the product to remove */
  product_id: number;
};
export type CartCheckoutResponse = {
  /** True if the cart was successfully checked out */
  success?: boolean;
};
export type CartCheckoutRequest = {
  /** The id for this conversation */
  cid: string;
};
export type VideoUploadResponse = {
  success?: boolean;
};
export type BodyUploadVideo = {
  video: Blob;
  metadata: Blob;
};
export type SummarizeResponse = {
  /** The store number for this conversation */
  store_num: number;
  /** The summary of the conversation for the specified audience. */
  summary: string;
};
export type SummaryAudience = "admin" | "client" | "customer";
export type SummaryFormat = "plaintext" | "html";
export type SummarizeRequest = {
  /** The id for this conversation */
  cid: string;
  /** The audience for the summary */
  audience: SummaryAudience;
  /** Formatting option for the summary */
  format?: SummaryFormat;
};
export type ConversationProductsResponse = {
  /** The products associated with this conversation. */
  products: SkuGroup[];
};
export type ConversationProductsRequest = {
  /** The id for this conversation */
  cid: string;
};
export type SubstituteResponse = {
  /** The budget SKU Group, if one is applicable */
  budget?: SkuGroup | null;
  /** The competitive SKU Group, if one is applicable */
  competitive?: SkuGroup | null;
  /** The premium SKU Group, if one is applicable */
  premium?: SkuGroup | null;
};
export type SubstituteRequest = {
  /** The client ID to find substitute products for. */
  client_id: string;
  /** The SKU Group ID to find substitute products for. */
  sgid: number;
  /** The store number to find substitute products for. */
  store_num: number;
};
export type HealthCheckResponse = {
  /** The name of the API */
  api_name: string;
  /** The version of the API */
  api_version: string;
  /** The version of the core library */
  core_version: string;
  /** The version of the types library */
  types_version: string;
  /** The time the API was started */
  live: string;
};
export type AuthResponse = {
  /** The token for this client */
  token: string;
};
export type AuthRequest = {
  /** The ID for the client making the request */
  client_id: string;
  /** The secret for the client making the request */
  client_secret: string;
  /** The store number for this kiosk or request. Optional if web request */
  store_num?: number | null;
  /** The kiosk number for this request. Optional if web request */
  kiosk_num?: number | null;
};
export const {
  useInitiateMutation,
  useSendMutation,
  useStreamMutation,
  usePollQuery,
  useFeedbackMutation,
  useReviewMutation,
  useTerminateMutation,
  useSttMutation,
  useTtsMutation,
  useTopPicksMutation,
  useLogMutation,
  useCartAddMutation,
  useCartRemoveMutation,
  useCartCheckoutMutation,
  useUploadVideoMutation,
  useSummarizeMutation,
  useProductsMutation,
  useSubstituteMutation,
  useHealthcheckQuery,
  useAuthMutation,
} = injectedRtkApi;
