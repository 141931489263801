import React, { useRef, useEffect } from "react";
import type { Swiper as SwiperType } from "swiper";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";


const IntroMessage: React.FC = () => {
    const swiperRef = useRef<SwiperType | null>(null);

    useEffect(() => {
        // Play the swiper autoplay
        if (swiperRef.current !== null) {
            swiperRef.current.autoplay.start();
        }
    }, []);

    return (
        <div className="px-10 pt-6 max-h-full">
            <p id="intro-message-text" className="font-sans antialiased text-center drop-shadow-4xl text-[5vw] text-emerald-100 leading-relaxed">
                Tap the Icon or Say "OK George" to:
            </p>
            <Swiper
                onSwiper={(swiper: SwiperType) => {
                    swiperRef.current = swiper;
                }}
                modules = {[Autoplay]}
                autoplay={{ delay: 13000, disableOnInteraction: false }}
                loop={true}
                slidesPerView={1}
                touchRatio={.1}
                threshold={10}
            >
                <SwiperSlide>
                    <p className="font-sans antialiased text-center drop-shadow-4xl text-[5vw] text-emerald-100 leading-relaxed">
                        Ask me for Product Recommendations
                    </p>
                </SwiperSlide>
                <SwiperSlide>
                    <p className="font-sans antialiased text-center drop-shadow-4xl text-[5vw] text-emerald-100 leading-relaxed">
                        Get an Expert Opinion on Our Products
                    </p>
                </SwiperSlide>
                <SwiperSlide>
                    <p className="font-sans antialiased text-center drop-shadow-4xl text-[5vw] text-emerald-100 leading-relaxed">
                        Find an Excellent Wine Pairing
                    </p>
                </SwiperSlide>
                <SwiperSlide>
                    <p className="font-sans antialiased text-center drop-shadow-4xl text-[5vw] text-emerald-100 leading-relaxed">
                        Get a Recipe for a Delicious Cocktail
                    </p>
                </SwiperSlide>
                <SwiperSlide>
                    <p className="font-sans antialiased text-center drop-shadow-4xl text-[5vw] text-emerald-100 leading-relaxed">
                        Check Location & Availability of Products
                    </p>
                </SwiperSlide>
                <SwiperSlide>
                    <p className="font-sans antialiased text-center drop-shadow-4xl text-[5vw] text-emerald-100 leading-relaxed">
                        Get Party Planning Help
                    </p>
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default IntroMessage;