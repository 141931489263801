import react, { memo } from 'react';

const BotThinkingBubble = () => {
    return (
        <div id="bot-speech-bubble"
            className={`relative z-10 -translate-x-[2vw] -translate-y-[2vh] w-[60vw] max-w-[75vw] max-h-[33%] gap-2.5 drop-shadow-3xl`
            }>
            <div className={`absolute bottom-0 px-16 w-[70vw] max-w-[75vw] max-h-[15vh] leading-1.5 p-4 bg-transparent overflow-clip`}>
                <img className="drop-shadow-2xl" src="typing-bubble.gif" alt="Bot is thinking" />
            </div>
        </div>
    );
};

export default BotThinkingBubble;