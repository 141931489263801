import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store, RootState } from '../redux/store';
import ChatAvatar from './chat/ChatAvatar';
import DialogView from './chat/DialogView';
import EndSignalHandler from './chat/EndSignalHandler';
import UserSpeechToText from './chat/UserSpeechToText';
import BotTextToSpeech from './chat/BotTextToSpeech';
import { EventLogRequest, LogApiArg, StreamApiArg, StreamRequest, useLogMutation, useStreamMutation } from '../redux/georgebotApi';
import {
    setRunId,
    clearRunId,
    clearCurrentUserMessageInProgress,
    clearCurrentBotMessage,
    clearCurrentBotMessageBuffer,
    updateCurrentBotMessageFromQueue,
    setListening,
    setThinking,
    clearCurrentSystemMessage,
    incrementErrorCount,
    resetErrorCount,
    setCurrentBotMessageBuffer,
} from '../redux/chatSlice';
import MessagePoller from './chat/MessagePoller';
import '../App.css';
import { ChatStateEnum, UIStateEnum } from '../redux/types';

interface ChatWindowProps {
    cid?: string;
};

const ChatWindow: React.FC<ChatWindowProps> = ({ cid }) => {
    const dispatch = useDispatch();
    const [event, { data: eventData, error: eventError, isLoading: eventLoading }] = useLogMutation();
    const uiState = useSelector((state: RootState) => state.ui.state);
    const currentUserMessage = useSelector((state: RootState) => state.chat.currentUserMessage);
    const currentSystemMessage = useSelector((state: RootState) => state.chat.currentSystemMessage);
    const currentBotMessageBuffer = useSelector((state: RootState) => state.chat.currentBotMessageBuffer);
    const botMessageQueue = useSelector((state: RootState) => state.chat.botMessageQueue).messages;
    const runId = useSelector((state: RootState) => state.chat.run_id);
    const [streamMessage, { data: streamData, error: streamError, isLoading: streamLoading }] = useStreamMutation();

    const handleSendMessage = async (message: string) => {
        const currentCid = store.getState().chat.cid;
        if (currentCid === undefined) {
            console.log('CID is undefined');
            return;
        }
        console.log('Sending message:', message);
        const streamRequest: StreamRequest = {
            cid: currentCid,
            text: message,
            speech: true,
        };
        const streamResponse = await streamMessage({
            "x-token": localStorage.getItem('token') as string,
            streamRequest: streamRequest
        } as StreamApiArg).unwrap();
        console.log('Data:', streamResponse);
        const runId = streamResponse.run_id;
        console.log('Run ID:', runId);
        dispatch(setRunId(runId));
    };

    const handlePollComplete = (runId: string, isError: boolean) => {
        try {
            console.log('Poll complete:', runId);
            dispatch(clearRunId());
            if (isError === true) {

            }
        }
        catch (error: any) {
            console.log('Error completing message poll:', error);
            event({
                "x-token": localStorage.getItem('token') as string,
                eventLogRequest: {
                    cid: store.getState().chat.cid?.toString(),
                    event: 'kiosk_error',
                    data: { error: `Error in ChatWindow.HandlePollComplete: ${error.toString()}` },
                } as EventLogRequest
            } as LogApiArg);
        }
    };

    const handleBotSpeechComplete = () => {
        dispatch(clearCurrentSystemMessage());
        try {
            const currentBotMessageQueue = store.getState().chat.botMessageQueue.messages;
            console.log('Bot speech complete');
            dispatch(clearCurrentBotMessage());
            dispatch(clearCurrentBotMessageBuffer());
            if (currentBotMessageQueue.length > 0) {
                dispatch(setThinking());
            }
            else {
                dispatch(resetErrorCount());
                dispatch(setListening());
                dispatch(clearCurrentUserMessageInProgress());
            }
        } catch (error: any) {
            console.log('Error:', error);
            event({
                "x-token": localStorage.getItem('token') as string,
                eventLogRequest: {
                    cid: store.getState().chat.cid?.toString(),
                    event: 'kiosk_error',
                    data: { error: `Error in ChatWindow.handleBotSpeechComplete: ${error.toString()}` },
                } as EventLogRequest
            } as LogApiArg);
        };
    };

    useEffect(() => {
        if (cid) {
            console.log('Current CID:', cid);
        }
    }, [cid]);

    useEffect(() => {
        if (currentUserMessage) {
            handleSendMessage(currentUserMessage);
        }
    }, [currentUserMessage]);

    useEffect(() => {
        const currentBotMessageQueue = store.getState().chat.botMessageQueue.messages;
        if (currentBotMessageBuffer === undefined && currentBotMessageQueue.length > 0) {
            dispatch(updateCurrentBotMessageFromQueue());
        }
    }, [currentBotMessageBuffer]);

    // Update the current bot message from the queue 
    // when there is a change to the queue and the current message is undefined
    useEffect(() => {
        const currentBotMessageBuffer = store.getState().chat.currentBotMessageBuffer;
        if (currentBotMessageBuffer === undefined && botMessageQueue.length > 0) {
            console.log('Updating current bot message from queue');
            dispatch(updateCurrentBotMessageFromQueue());
        }
    }, [botMessageQueue]);

    return (
        <div>
            <div className="flex justify-center h-auto">
                <div className="relative h-auto w-auto">
                    <div
                        className={`transition-transform duration-500 ${[UIStateEnum.active, UIStateEnum.starting, UIStateEnum.error].includes(uiState) ? '-translate-x-[34vw]' : ' animate-pulse'
                            }`}
                    >
                        <ChatAvatar />
                    </div>
                    <div id="dialog-view" className="absolute translate-x-[20vw] -translate-y-[13vh] pt-9 max-w-[75vw]">
                        {uiState === "active" && <DialogView />}
                    </div>
                </div>
            </div>
            {[UIStateEnum.active, UIStateEnum.starting].includes(uiState) && <UserSpeechToText />}
            {runId !== undefined && cid &&
                <MessagePoller
                    cid={cid}
                    runId={runId}
                    onComplete={(error) => handlePollComplete(runId, error)}
                />}
            {currentBotMessageBuffer &&
                <BotTextToSpeech
                    message={currentBotMessageBuffer.message}
                    onComplete={() => handleBotSpeechComplete()}
                />}
            {uiState === "active" && <EndSignalHandler />}
        </div>
    );
};

export default ChatWindow;